import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Hero } from "../components/Hero"
import { TextColumns } from "../components/TextColumns"
import { Image } from "../components/Image"

const ParkHillsImage = number => `ParkHills-0${number}.png`

const ParkHillsPage = () => {
  return (
    <Layout isProjectPage>
      <SEO />

      <Hero>
        Park Hills
        <Image src={ParkHillsImage(1)} />
      </Hero>

      <TextColumns offset>
        <div></div>
        <div>Steve Zagorski Architects</div>
        <div></div>
      </TextColumns>

      {[...Array(1)].map((image, index) => (
        <Image
          key={`ParkHills-${index}`}
          style={{ margin: "80px 0px" }}
          src={ParkHillsImage(index + 1)}
        />
      ))}
    </Layout>
  )
}

export default ParkHillsPage
